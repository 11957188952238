import React from 'react'
import {
  CFSelect,
  CFView,
  CFImage,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { downloadButton, menu } from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getPdfMenuUrl}>
      {pdfMenuUrl => (
        <CFView>
          <MobileScreen>
            <CFView column center>
              <CFImage src={pdfMenuUrl} w="100%" alt="Food Gallery" />
            </CFView>
          </MobileScreen>
          <DefaultScreen>
            <CFView column center>
              <CFImage src={pdfMenuUrl} w="100%" alt="Food Gallery" />
            </CFView>
          </DefaultScreen>
        </CFView>
      )}
    </CFSelect>
  )
}
